<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <g clip-path="url(#clip0_581_3594)">
            <path
                d="M13.0656 0.000976562H4.93443C2.21358 0.000976562 0 2.21455 0 4.93544V13.0666C0 15.7874 2.21358 18.001 4.93443 18.001H13.0656C15.7865 18.001 18 15.7874 18 13.0666V4.93544C18 2.21455 15.7865 0.000976562 13.0656 0.000976562ZM16.937 13.0666C16.937 15.2013 15.2003 16.938 13.0656 16.938H4.93443C2.7997 16.938 1.06298 15.2013 1.06298 13.0666V4.93544C1.06298 2.80068 2.7997 1.06396 4.93443 1.06396H13.0656C15.2003 1.06396 16.937 2.80068 16.937 4.93544V13.0666Z"
                fill="currentColor"
            />
            <path
                d="M9 4.06396C6.27771 4.06396 4.06297 6.2787 4.06297 9.00099C4.06297 11.7233 6.27768 13.938 9 13.938C11.7223 13.938 13.9371 11.7232 13.9371 9.00096C13.9371 6.27867 11.7223 4.06396 9 4.06396ZM9 12.8751C6.86384 12.8751 5.12592 11.1371 5.12592 9.00096C5.12592 6.86479 6.86384 5.12688 9 5.12688C11.1362 5.12688 12.8741 6.86483 12.8741 9.00099C12.8741 11.1372 11.1362 12.8751 9 12.8751Z"
                fill="currentColor"
            />
            <path
                d="M14.2323 2.26855C13.4053 2.26855 12.7324 2.94148 12.7324 3.76857C12.7324 4.59565 13.4053 5.26854 14.2323 5.26854C15.0594 5.26854 15.7323 4.59562 15.7323 3.76853C15.7323 2.94145 15.0594 2.26855 14.2323 2.26855ZM14.2323 4.20552C13.9914 4.20552 13.7953 4.00949 13.7953 3.76853C13.7953 3.52757 13.9914 3.33154 14.2323 3.33154C14.4733 3.33154 14.6693 3.52761 14.6693 3.76853C14.6693 4.00946 14.4733 4.20552 14.2323 4.20552Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_581_3594">
                <rect width="18" height="18" fill="white" transform="translate(0 0.000976562)" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style></style>
